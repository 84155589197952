/* Override star rating colors by using scss variables */
$color-ok-rating: #33ccff !default;

/* Import star-rating styles */
@import 'css-star-rating/scss/star-rating';

@keyframes spin {
  from {transform:rotate(0deg);}
  to {transform:rotate(360deg);}
}

@keyframes vanish {
  to {
    opacity: 0;
  }
}

#dropzone {
  margin-bottom: 14px;
  .wrapper {
    border-radius: 3px;
    background-color: white;
    padding: 14px;
    position: relative;
  }
  .overlay {
    background-color: white;
    text-align: center;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 10;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
  }
  .load {
    margin-bottom: 14px;
    i {
      animation: spin 2.5s infinite linear;
      // color: theme-color('primary');
      font-size: 28px;
    }
  }
  .vanish {
    animation: vanish 2s linear;
  }
}

dropzone>.dropzone.dz-wrapper.dz-single.dz-started .dz-message {
  display: block !important;
}

.dropzone {
  &.dz-wrapper {
    .dz-message {
      background-color: whitesmoke !important;
      border: 2px dashed rgb(0, 179, 255) !important;
      border-radius: 3px;
      min-height: 35vh !important;
      max-width: 100% !important;
      width: 100% !important;
      margin: 0 !important;
      .dz-text {
        i, h4, p {
          color: darken(whitesmoke, 50%);
        }
        i {
          margin-bottom: 14px;
          font-size: 28px;
          display: inline-block;
        }
      }
    }
    .dz-preview {
      display: none;
    }
  }
}
