@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import 'primeng/resources/themes/lara-light-blue/theme.css';
@import 'primeng/resources/primeng.css';

$color-ok-rating: #33ccff !default;

@mixin circle-headshot {
  .circle-headshot {
    background-position: center center;
    background-size: cover;
    display: inline-block;
    background-repeat: no-repeat;
  }
}